p {
  overflow: hidden;
  text-align: center;
}

.register_header p:before,
.register_header p:after {
  background-color: #707070;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

p:before {
  right: 0.5em;
  margin-left: -50%;
}

p:after {
  left: 0.5em;
  margin-right: -50%;
}
