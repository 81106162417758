.text_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 10px;
}

h3 {
  margin-bottom: 30px;
}

section {
  text-align: left;
  font-size: 16px;
}

section h2 {
  font-size: 16px;
  font-weight: bold;
}
