body {
  margin: 0;
  text-align: center;
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.noto-sans_jp-100 {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}
.noto-sans_jp-200 {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}
.noto-sans_jp-300 {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.noto-sans_jp-400 {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.noto-sans_jp-500 {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.noto-sans_jp-600 {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.noto-sans_jp-700 {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.noto-sans_jp-800 {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}
.noto-sans_jp-900 {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(1.15);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16'  height='8'  viewBox='0 0 16 8'> <path    id='Path_647'  data-name='Path 647' d='M0,0,8,8l8-8Z'  transform='translate(0 0)'  /></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 50%;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}
div {
  -webkit-tap-highlight-color: transparent;
}